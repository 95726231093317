import {
    type AttachmentInfos,
    type BaseChatMessage,
    TelehealthAppType,
} from '@bondvet/types/telehealth';
import type { Timestamp } from 'firebase/firestore';

export enum GraphQLClientNames {
    telehealth = 'telehealth',
    promoCodes = 'promoCodes',
    bondvet = 'bondvet',
    creditCards = 'creditCards',
    batch = 'batch',
}

export type Option = {
    key?: string;
    value: unknown;
    label: string;
    cardBrand?: string;
    badge?: { label: string; className: string } | null;
};

export type ChatMessage = BaseChatMessage<Timestamp>;

export type FormattedChatMessage = {
    attachments?: AttachmentInfos[];
    timestamp: string;
    sentAt: string; // Formatted to the displayed string
    message?: string;
    sender: TelehealthAppType;
    senderName: string;
    senderImage: string | null;
};

export type WaitingCard = {
    id: string;
    image?: File;
    title: string;
    text: string;
    position: number;
    imageUrl: string;
    thumbUrl: string;
};
