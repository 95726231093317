import { ClientData } from 'stripe';
import { FileUpload } from 'graphql-upload';
import type { Role } from '@bondvet/roles';
import { Animal } from '../animals';
import { Species } from '../booking';
import { IsomorphicTimestamp } from '../firebase';
import { Location as BaseLocation } from '../locations';
import { US_STATES } from './util';

export const TELEHEALTH_SESSIONS_COLLECTION_NAME = 'telehealth.sessions';
export const SESSION_IDS_COLLECTION_NAME = 'telehealth.sessionIds';
export const TELEHEALTH_BOOKINGSYNC_COLLECTION_NAME = 'telehealth.bookingSync';
export const CHAT_MESSAGES_COLLECTION_NAME = 'telehealth.chatMessages';
export const AVERAGE_WAITING_TIMES_COLLECTION_NAME =
    'telehealth.averageWaitingTimes';
export const PROVIDERS_COLLECTION_NAME = 'telehealth.providers';
export const PROVIDER_PINGS_COLLECTION_NAME = 'telehealth.providerPings';

export enum SessionStatus {
    nurseQueue = 'nurseQueue',
    nurseJoining = 'nurseJoining',
    nurseCall = 'nurseCall',
    veterinarianQueue = 'veterinarianQueue',
    veterinarianJoining = 'veterinarianJoining',
    veterinarianCall = 'veterinarianCall',
    finished = 'finished',
}

export type Attachment = Promise<FileUpload>;

export interface TelehealthSessionInput {
    email: string;
    phone: string;
    firstName: string;
    lastName: string;
    pet: string | null; // is passed if new pet
    species: Species | null; // is passed if new pet
    patientId: string | null; // is passed if existing pet
    reasonId: string;
    notes: string;
    attachments: ReadonlyArray<Attachment>;
}

export interface Rating {
    score: number;
    notes: string;
    ratedAt: IsomorphicTimestamp;
}

export type Software = {
    name?: string;
    version?: string;
};

export type SessionMetaData = {
    hasCameraPermission: boolean;
    hasMicrophonePermission: boolean;
    browser?: null | Software;
    operatingSystem?: null | Software;
};

export type AttachmentInfos = {
    vetspireId: string;
    filePath: string;
    thumbnailPath: string | null;
};

export interface PatientPlanTelehealthQuantities {
    quantity: number;
    quantityUsed: number;
    hasUnused: boolean;
}

export interface FirestoreTelehealthSession {
    attachments: AttachmentInfos[];
    /**
     * our internal client ID {@link User}
     */
    clientId: string;
    endedAt: IsomorphicTimestamp | null;
    /**
     * numeric ID of the Vetspire encounter
     */
    encounterId: string | null;
    inNurseQueueAt: IsomorphicTimestamp | null;
    inVeterinarianQueueAt: IsomorphicTimestamp | null;
    patientKnown: boolean;
    /**
     * notes added by the client in the intake form. will
     * pre-populate the "Chief Complaint" in the Vetspire
     * encounter.
     */
    notes: string;
    nurseJoinedAt: IsomorphicTimestamp | null;
    /**
     * ID of the Firestore document in `telehealth.sessions`
     */
    nurseId: string | null;
    nurseQueuePosition: null | number;
    /**
     * our internal patient ID {@link Animal}
     */
    patientId: string | null;
    /** numeric Vetspire ID */
    vetspirePatientId: string | null;
    /** numeric Vetspire ID */
    vetspirePatientPlanId: string | null;
    /** numeric Vetspire ID */
    vetspirePreventionPlanId: string | null;
    /** friendly name of the prevention plan */
    preventionPlanPublicName: string | null;
    /** patient plan telehealth quantities before the telehealth session */
    patientPlanTelehealthQuantities: PatientPlanTelehealthQuantities | null;
    /** Stripe ID of payment intent, should only be null if an unused plan item was available */
    paymentIntentId: string | null;
    phone: string;
    rating: null | Rating;
    /**
     * internal ID of the `telehealth.reasons` entry
     */
    reasonId: string;
    /**
     * numeric ID of the Vetspire appointment type
     */
    appointmentTypeId: string;
    status: SessionStatus;
    /**
     * ID of the Firestore document in `telehealth.sessions`
     */
    veterinarianId: string | null;
    veterinarianJoinedAt: IsomorphicTimestamp | null;
    veterinarianQueuePosition: null | number;
    metaData?: SessionMetaData;
    orderId: string | null;
}

export type ProviderFirestoreSessionIds = {
    zoomAuthToken: string;
};

export type ClientFirestoreSessionIds = ProviderFirestoreSessionIds & {
    clientId: string;
    sessionId: string; // also used as zoom session name
    authToken: string; // Firebase auth token
};

export interface FirestoreSessionIds {
    client: ClientFirestoreSessionIds;
    provider: ProviderFirestoreSessionIds | null;
    sessionPassword: string;
    zoomSessionId: string;
}

export interface FirestoreAverageWaitingTime {
    minutes: number;
}

export interface FirestoreProvider {
    vetspireId: string;
    active: boolean;
    licensedStates: string[]; // US-State abbreviation.
    phone: string | null;
    lastActiveAt: IsomorphicTimestamp;
    inCall: boolean;
    getNotifications: boolean;
}

export type FirestoreProviderPing = {
    lastPingAt: IsomorphicTimestamp;
};

export type TelehealthSessionResult = {
    error?: null | string;
    sessionIds?: null | ClientFirestoreSessionIds;
    rejoinId?: null | string;
    sessionPassword?: null | string;
};

export type EnterVideoCallResult = {
    zoomAuthToken: string;
    sessionPassword: string;
};

export enum TelehealthAppType {
    client = 'client',
    provider = 'provider',
}

export interface MessageInput {
    message: string;
    attachments: ReadonlyArray<Attachment>;
}

export interface BaseChatMessage<TDate = Date> {
    attachments: AttachmentInfos[];
    createdAt: TDate;
    message: string | null;
    providerId: string | null;
    sender: TelehealthAppType;
    sessionId: string;
}

export type Patient = Pick<
    Animal,
    '_id' | 'name' | 'species' | 'sex' | 'userId'
> & {
    client: ClientData;
    _vetspire: { id: string };
    name: string;
    patientPlan?: {
        id: string;
        preventionPlan: {
            id: string;
            name: string;
        };
        telehealthQuantity: number;
        telehealthQuantityUsed: number;
    } | null;
};

export type LocationWithTimeSlots = {
    vetspireLocationId: string;
    timeSlots: string[]; // datetime, e.g. 2022-01-28T11:30:00.000Z
};

export type StateAbbreviation = keyof typeof US_STATES;
export type StateName = (typeof US_STATES)[keyof typeof US_STATES];

export type LicensedState = {
    id: StateAbbreviation;
    name: StateName;
};

export type Encounter = {
    id: string;
};

export type Location = Pick<
    BaseLocation,
    '_id' | 'coordinates' | 'name' | 'timezone' | '_vetspire'
>;

export type GraphqlProvider = {
    id: string; // firebaseId
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    role: Role.nurse | Role.veterinarian;
};

export type OpeningHours = {
    from: string;
    to: string;
};

export type OpeningHoursDays = {
    sunday: ReadonlyArray<OpeningHours>;
    monday: ReadonlyArray<OpeningHours>;
    tuesday: ReadonlyArray<OpeningHours>;
    wednesday: ReadonlyArray<OpeningHours>;
    thursday: ReadonlyArray<OpeningHours>;
    friday: ReadonlyArray<OpeningHours>;
    saturday: ReadonlyArray<OpeningHours>;
};
export type Availability = {
    open: boolean;
    timezone: string;
    openingHours: OpeningHoursDays;
    blockedOffUntil?: string;
    blockedOffUntilEndOfDay?: boolean;
};

export enum TwilioVideoStreamNames {
    client = 'client-video-stream',
    provider = 'provider-video-stream',
}

export type BookingAppointment = {
    dateTime?: string | null;
    location?: Location | null;
    coordinates?: {
        __typename?: string;
        latitude?: number;
        longitude?: number;
    };
    name?: string;
    timezone?: string;
};

export type ConfirmedAppointment = BookingAppointment & {
    id: string | null;
};

export interface BookingSync {
    active: boolean;
    activeTab: string | number;
    appointmentNote: string;
    height: number;
    updatedAt: IsomorphicTimestamp;
    confirmedAppointment: ConfirmedAppointment;
    newAppointment: ConfirmedAppointment;
    scheduleLocation: string;
    selectedDate: IsomorphicTimestamp;
    selectedLocations: string[];
    width: number;
    scrollPosition: number;
    mapCenter: {
        lat: number;
        lng: number;
    };
    mapZoom: number;
}

export const TELEHEALTH_REASONS_COLLECTION_NAME = 'telehealth.reasons';

export interface TelehealthReason {
    _id: string;
    name: string;
    active: boolean;
    appointmentTypeId: string | null;
}

export interface PatientPlanTelehealthQuantitiesQueryArguments {
    patientPlanId: string;
}

export interface PatientPlanTelehealthQuantitiesQueryResult {
    patientPlanTelehealthQuantities: PatientPlanTelehealthQuantities | null;
}
