import * as React from 'react';
import type {
    SessionMetaData,
    FirestoreTelehealthSession,
} from '@bondvet/types/telehealth';
import { TELEHEALTH_SESSIONS_COLLECTION_NAME } from '@bondvet/types/telehealth';
import {
    doc,
    getFirestore,
    updateDoc,
    type DocumentReference,
} from 'firebase/firestore';
import useFirebaseContext from 'core/hooks/firebase/useFirebaseContext';

type UseSessionMetaData = {
    setHasMicrophonePermission: (hasMicrophonePermission: boolean) => void;
    setHasCameraPermission: (hasCameraPermission: boolean) => void;
};

export default function useSessionMetaData(
    sessionId: string | undefined,
): UseSessionMetaData {
    const { firebase } = useFirebaseContext();
    const ref =
        React.useMemo<null | DocumentReference<FirestoreTelehealthSession>>(() => {
            if (!sessionId || !firebase) {
                return null;
            }

            return doc(
                getFirestore(firebase),
                TELEHEALTH_SESSIONS_COLLECTION_NAME,
                sessionId,
            ) as DocumentReference<FirestoreTelehealthSession>;
        }, [firebase, sessionId]);

    const updateSessionMetaData = React.useCallback(
        (
            field: keyof SessionMetaData,
            value: SessionMetaData[keyof SessionMetaData],
        ) => {
            if (ref) {
                updateDoc(ref, {
                    [`metaData.${String(field)}`]: value,
                }).then(
                    () => {},
                    (error) => {
                        console.warn(
                            'error trying to update metaData.%s: %s',
                            field,
                            (error as Error).message,
                            value,
                        );
                    },
                );
            }
        },
        [ref],
    );

    // we automatically store browser and OS
    React.useEffect(() => {
        const updateBrowserAndOs = async () => {
            const { UAParser } = await import('ua-parser-js');
            const ua = new UAParser();

            const browser = await ua.getBrowser().withClientHints();
            const os = ua.getOS();

            await Promise.all([
                updateSessionMetaData('browser', {
                    name: browser.name,
                    version: browser.version,
                }),
                updateSessionMetaData('operatingSystem', {
                    name: os.name,
                    version: os.version,
                }),
            ]);
        };

        if (ref) {
            updateBrowserAndOs().then();
        }
    }, [updateSessionMetaData, ref]);

    const setHasMicrophonePermission = React.useCallback(
        (hasMicrophonePermission: boolean) => {
            updateSessionMetaData(
                'hasMicrophonePermission',
                hasMicrophonePermission,
            );
        },
        [updateSessionMetaData],
    );
    const setHasCameraPermission = React.useCallback(
        (hasCameraPermission: boolean) => {
            updateSessionMetaData('hasCameraPermission', hasCameraPermission);
        },
        [updateSessionMetaData],
    );

    return { setHasCameraPermission, setHasMicrophonePermission };
}
