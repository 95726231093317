import * as React from 'react';
import { gql } from '@apollo/client';
import { TelehealthAppType } from '@bondvet/types/telehealth';
import ZoomVideo, {
    type ConnectionChangePayload,
    ConnectionState,
    type ExecutedFailure,
    type ParticipantPropertiesPayload,
    type VideoClient,
    type VideoPlayer,
} from '@zoom/videosdk';
import useSessionMetaData from '../useSessionMetaData';
import useTelehealthQuery from '../useTelehealthQuery';
import {
    type CallMetaData,
    type ExtendedLocalVideoTrack,
    InitialVideoContext,
    type VideoContextData,
    type VideoContextType,
} from './context';

const viewerNameQuery = gql`
    query viewerName {
        viewerName
    }
`;

type ViewerNameQueryResult = {
    viewerName: string;
};

type CurrentCameraData = {
    availableVideoDeviceIds: string[];
    currentVideoDeviceId: string;
};

function getVideoDevices(
    devices: Array<MediaDeviceInfo>,
): Array<MediaDeviceInfo> {
    return devices.filter((dev) => dev.label && dev.kind === 'videoinput');
}

function getAudioDevices(
    devices: Array<MediaDeviceInfo>,
): Array<MediaDeviceInfo> {
    return devices.filter((dev) => dev.label && dev.kind === 'audioinput');
}

export default function useVideoContextHandler(
    appType: TelehealthAppType,
    sessionId?: string,
): VideoContextType {
    const zoomSession = React.useRef<null | ReturnType<
        (typeof VideoClient)['getMediaStream']
    >>(null);

    const { data: viewerNameData } = useTelehealthQuery<ViewerNameQueryResult>(
        viewerNameQuery,
        {
            fetchPolicy: 'no-cache',
        },
    );

    const viewerName = viewerNameData?.viewerName ?? null;

    const zoomVideo = React.useMemo(() => {
        return ZoomVideo.createClient();
    }, []);

    const [
        { availableVideoDeviceIds, currentVideoDeviceId },
        setCurrentCameraData,
    ] = React.useState<CurrentCameraData>(() => ({
        availableVideoDeviceIds: [],
        currentVideoDeviceId: '',
    }));

    const [
        {
            microphoneOn,
            cameraOn,
            otherParticipantJoined,
            localVideoTrack,
            hasReceivedPermissions,
            hasLostPermissions,
            userPermissionsGranted,
        },
        setValue,
    ] = React.useState<VideoContextData>(InitialVideoContext);

    const localVideoRef = React.useRef<HTMLVideoElement | null>(null);

    const remoteVideoPlayers = React.useRef<null | Record<
        string,
        VideoPlayer | null
    >>({});
    const [remoteParticipants, setRemoteParticipants] = React.useState<
        readonly number[]
    >(() => []);

    const { setHasMicrophonePermission, setHasCameraPermission } =
        useSessionMetaData(sessionId);

    const setSessionMetaData = React.useCallback(
        async ({
            hasMicrophonePermission,
            hasCameraPermission,
        }: CallMetaData) => {
            if (appType === TelehealthAppType.client) {
                setHasCameraPermission(hasCameraPermission);
                setHasMicrophonePermission(hasMicrophonePermission);
            }
        },
        [appType, setHasMicrophonePermission, setHasCameraPermission],
    );

    const switchCamera = React.useCallback(() => {
        setCurrentCameraData((prev) => {
            const currentIdx = prev.currentVideoDeviceId
                ? Math.max(
                      prev.availableVideoDeviceIds.indexOf(
                          prev.currentVideoDeviceId,
                      ),
                      0,
                  )
                : 0;

            const nextIdx =
                (currentIdx + 1) % prev.availableVideoDeviceIds.length;

            return {
                ...prev,
                currentVideoDeviceId: prev.availableVideoDeviceIds[nextIdx],
            };
        });
    }, []);

    React.useEffect(() => {
        if (!currentVideoDeviceId) {
            return;
        }

        if (zoomSession.current && zoomVideo) {
            if (zoomVideo.getCurrentUserInfo().bVideoOn) {
                zoomSession.current
                    .switchCamera(currentVideoDeviceId)
                    .catch((error) => {
                        console.warn('error switching published camera', error);
                    });
            }
        }

        if (
            localVideoTrack &&
            localVideoTrack.deviceId !== currentVideoDeviceId &&
            localVideoTrack.isVideoStarted
        ) {
            localVideoTrack
                .switchCamera(currentVideoDeviceId)
                .catch((error) => {
                    console.warn(
                        'error switching camera on local video track',
                        error,
                    );
                });
        }
    }, [currentVideoDeviceId, zoomVideo, localVideoTrack]);

    const startLocalAudioStream = React.useCallback(async () => {
        if (zoomSession.current) {
            zoomSession.current.unmuteAudio().then();
        }
    }, [zoomSession]);

    const createLocalVideoTrack = React.useCallback(async () => {
        if (localVideoTrack) {
            try {
                await localVideoTrack.stop();
            } catch (error) {
                // ignore
            }
        }

        const cameraDeviceId =
            currentVideoDeviceId || zoomSession.current?.getActiveCamera();

        const newVideoTrack = ZoomVideo.createLocalVideoTrack(
            cameraDeviceId,
        ) as ExtendedLocalVideoTrack;

        setValue((prev) => ({
            ...prev,
            localVideoTrack: newVideoTrack,
        }));

        if (cameraDeviceId !== currentVideoDeviceId) {
            const activeCamera = zoomSession.current?.getActiveCamera();

            if (activeCamera) {
                setCurrentCameraData((prev) => ({
                    ...prev,
                    currentVideoDeviceId: activeCamera,
                }));
            }
        }

        return cameraDeviceId;
    }, [localVideoTrack, currentVideoDeviceId]);

    const endLocalVideoStream = React.useCallback(async () => {
        const promises: Promise<unknown>[] = [];
        if (localVideoTrack && localVideoTrack.isVideoStarted) {
            promises.push(localVideoTrack.stop());
        }

        if (zoomSession.current) {
            promises.push(zoomSession.current.stopVideo());
        }

        await Promise.all(promises);

        setValue((prev) => ({
            ...prev,
            localVideoTrack: null,
        }));
    }, [localVideoTrack]);

    const startLocalVideoStream = React.useCallback(async () => {
        const cameraDeviceId = await createLocalVideoTrack();

        if (zoomSession.current) {
            try {
                await zoomSession.current.startVideo({
                    cameraId: cameraDeviceId,
                    mirrored: true,
                    originalRatio: true,
                    hd: zoomSession.current.isSupportHDVideo(),
                });
            } catch (error) {
                console.warn('could not start video', error);
                await endLocalVideoStream();
            }
        }
    }, [createLocalVideoTrack, endLocalVideoStream]);

    const endLocalAudioStream = React.useCallback(async () => {
        if (zoomSession.current) {
            await zoomSession.current.muteAudio();
        }
    }, [zoomSession]);

    const toggleCamera = React.useCallback(() => {
        setValue((prev) => ({
            ...prev,
            cameraOn: !cameraOn,
        }));
        if (cameraOn) {
            endLocalVideoStream().then();
        } else {
            startLocalVideoStream().then();
        }
    }, [endLocalVideoStream, startLocalVideoStream, cameraOn]);

    const toggleMicrophone = React.useCallback(() => {
        setValue(({ microphoneOn: wasMicrophoneOn, ...rest }) => {
            if (wasMicrophoneOn) {
                endLocalAudioStream().then();
            } else {
                startLocalAudioStream().then();
            }

            return {
                ...rest,
                microphoneOn: !wasMicrophoneOn,
            };
        });
    }, [endLocalAudioStream, startLocalAudioStream]);

    const pollIdRef = React.useRef<number>();
    const contextAlive = React.useRef(true);

    const [metaData, setMetaData] = React.useState<CallMetaData>();

    React.useEffect(() => {
        const interval = setInterval(() => {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                const hasCameraPermission = getVideoDevices(devices).length > 0;
                const hasMicrophonePermission =
                    getAudioDevices(devices).length > 0;
                if (
                    hasMicrophonePermission !==
                        metaData?.hasMicrophonePermission ||
                    hasCameraPermission !== metaData.hasCameraPermission
                ) {
                    setSessionMetaData({
                        hasCameraPermission,
                        hasMicrophonePermission,
                    });
                    setMetaData({
                        hasCameraPermission,
                        hasMicrophonePermission,
                    });
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [
        metaData?.hasCameraPermission,
        metaData?.hasMicrophonePermission,
        setSessionMetaData,
    ]);

    const pollDevices = React.useCallback(() => {
        if (contextAlive.current) {
            if (pollIdRef.current) {
                window.clearInterval(pollIdRef.current);
            }

            const checkDevices = () => {
                if (zoomSession.current) {
                    // I found that when the user doesn't allow camera access,
                    // `getCameraList` returns 1 entry with `deviceId` and `label` empty
                    const devices = zoomSession.current
                        .getCameraList()
                        .filter(
                            (device) =>
                                device.deviceId !== '' && device.label !== '',
                        );

                    const permissionGranted = devices.length > 0;

                    console.info(
                        'CHECK DEVICES',
                        permissionGranted,
                        devices.length,
                        devices,
                    );

                    setCurrentCameraData((prev) => ({
                        ...prev,
                        availableVideoDeviceIds: devices.map(
                            (device) => device.deviceId,
                        ),
                    }));

                    setValue((prev) => {
                        // hasLostPermissions and hasReceivedPermissions should only be set
                        // to `true`, if we have _just_ lost/received the permissions
                        // (i.e. we detected a state change in that regard)
                        let newHasLostPermissions = prev.hasLostPermissions;

                        if (prev.userPermissionsGranted && !permissionGranted) {
                            newHasLostPermissions = false;
                        }

                        let newHasReceivedPermissions =
                            prev.hasReceivedPermissions;

                        if (permissionGranted && !prev.userPermissionsGranted) {
                            newHasReceivedPermissions = true;
                        }

                        return {
                            ...prev,
                            userPermissionsGranted: permissionGranted,
                            hasReceivedPermissions: newHasReceivedPermissions,
                            hasLostPermissions: newHasLostPermissions,
                        };
                    });
                }
            };

            pollIdRef.current = window.setInterval(checkDevices, 500);
            checkDevices();
        }

        return () => {
            if (pollIdRef.current) {
                window.clearInterval(pollIdRef.current);
            }
        };
    }, []);

    // create/remove device-tracks if user allows/denies device-permissions
    React.useEffect(() => {
        if (hasReceivedPermissions) {
            setValue((prev) => ({
                ...prev,
                hasReceivedPermissions: false,
            }));

            if (cameraOn && !localVideoTrack) {
                startLocalVideoStream().then();
            }

            if (microphoneOn) {
                startLocalAudioStream().then();
            }
        }

        if (hasLostPermissions) {
            setValue((prev) => ({
                ...prev,
                hasLostPermissions: false,
            }));
            endLocalVideoStream();
            endLocalAudioStream();
        }
    }, [
        cameraOn,
        endLocalAudioStream,
        endLocalVideoStream,
        hasLostPermissions,
        hasReceivedPermissions,
        microphoneOn,
        startLocalAudioStream,
        startLocalVideoStream,
        localVideoTrack,
    ]);

    const addRemoteVideo = React.useCallback(
        (userId: number) => {
            const userIdString = userId.toString(10);
            if (
                !(userIdString in (remoteVideoPlayers.current ?? {})) &&
                zoomSession.current
            ) {
                // make sure we don't re-attach while we're waiting for the video player
                if (remoteVideoPlayers.current) {
                    remoteVideoPlayers.current[userIdString] = null;
                }
                zoomSession.current
                    .attachVideo(
                        userId,
                        zoomSession.current.getVideoMaxQuality(),
                    )
                    .then(
                        (participantVideo) => {
                            if ((participantVideo as ExecutedFailure).reason) {
                                console.warn(
                                    'error attaching video:',
                                    (participantVideo as ExecutedFailure)
                                        .reason,
                                );
                            } else {
                                const videoPlayer =
                                    participantVideo as VideoPlayer;

                                remoteVideoPlayers.current = {
                                    ...remoteVideoPlayers.current,
                                    [userIdString]: videoPlayer,
                                };

                                document
                                    .querySelector('video-player-container')
                                    ?.appendChild(videoPlayer);

                                videoPlayer.classList.add('remote-video');
                            }
                        },
                        (error) => {
                            console.warn(
                                'error attaching remote video player',
                                error,
                            );

                            if (remoteVideoPlayers.current) {
                                delete remoteVideoPlayers.current[userIdString];
                            }
                        },
                    );
            }
        },
        [remoteVideoPlayers],
    );

    const connectedToCall = React.useRef(false);

    const checkPermissions = React.useCallback(async (): Promise<{
        camera: boolean;
        microphone: boolean;
    }> => {
        let camera = false;
        let microphone = false;

        try {
            // Safari 15 doesn't support navigator.permissions ...
            const [cameraResult, microphoneResult] = navigator.permissions
                ? await Promise.all([
                      navigator.permissions.query({
                          name: 'camera' as PermissionName,
                      }),
                      navigator.permissions.query({
                          name: 'microphone' as PermissionName,
                      }),
                  ])
                : // ... so we fake the need for asking
                  [{ state: 'prompt' }, { state: 'prompt' }];

            const askForCamera = cameraResult.state === 'prompt';
            const askForMicrophone = microphoneResult.state === 'prompt';

            camera = cameraResult.state === 'granted';
            microphone = microphoneResult.state === 'granted';

            if (!askForCamera && !askForMicrophone) {
                return {
                    camera,
                    microphone,
                };
            }

            let mediaStream: null | Awaited<
                ReturnType<typeof navigator.mediaDevices.getUserMedia>
            > = await navigator.mediaDevices.getUserMedia({
                audio: askForMicrophone,
                video: askForCamera,
            });

            // we don't use this mediaStream just yet, so let's clean up
            const tracks = mediaStream.getTracks();

            for (const track of tracks) {
                track.stop();
            }

            mediaStream = null;

            return { camera: true, microphone: true };
        } catch (error) {
            console.warn('error checking permissions', error, {
                camera,
                microphone,
            });

            return { camera, microphone };
        }
    }, []);

    const joinSession = React.useCallback(
        async (
            zoomAuthToken: string,
            sessionName: string,
            sessionPassword: string,
            onDisconnected?: () => void,
        ) => {
            if (connectedToCall.current) {
                return;
            }

            connectedToCall.current = true;

            // we don't need permissions to connect the call.
            // we just need it to connect mic and/or camera
            const permissionsGranted = await checkPermissions();

            setValue((prev) => ({
                ...prev,
                userPermissionsGranted:
                    permissionsGranted.camera && permissionsGranted.microphone,
            }));

            try {
                await zoomVideo.init('en-US', 'Global', {
                    patchJsMedia: true,
                    leaveOnPageUnload: true,
                });

                await zoomVideo.join(
                    sessionName,
                    zoomAuthToken,
                    viewerName ?? '',
                    sessionPassword,
                );

                zoomSession.current = zoomVideo.getMediaStream();

                const startAudio = async (): Promise<void> => {
                    if (zoomSession.current) {
                        try {
                            await zoomSession.current.startAudio({
                                mute: !microphoneOn,
                            });
                        } catch (error) {
                            console.warn('START AUDIO FAILED', error);
                            const { reason, type } = error as ExecutedFailure;

                            if (
                                type === 'INVALID_OPERATION' &&
                                reason?.includes('please wait')
                            ) {
                                await new Promise<void>((resolve) => {
                                    setTimeout(resolve, 400);
                                });

                                await startAudio();
                            }
                        }
                    }
                };

                const startVideo = async (): Promise<void> => {
                    if (zoomSession.current) {
                        const cameraId = await createLocalVideoTrack();

                        try {
                            await zoomSession.current.startVideo({
                                cameraId,
                                mirrored: true,
                                originalRatio: true,
                                hd: zoomSession.current.isSupportHDVideo(),
                            });
                        } catch (error) {
                            console.warn('START VIDEO FAILED', error);

                            const { reason, type } = error as ExecutedFailure;

                            if (
                                type === 'INVALID_OPERATION' &&
                                reason?.includes('please wait')
                            ) {
                                await new Promise<void>((resolve) => {
                                    setTimeout(resolve, 400);
                                });

                                await startVideo();
                            }
                        }
                    }
                };

                if (permissionsGranted.camera) {
                    await startVideo();
                }

                if (permissionsGranted.microphone) {
                    await startAudio();
                }

                setValue((prev) => ({
                    ...prev,
                    cameraOn: permissionsGranted.camera,
                    microphoneOn: permissionsGranted.microphone,
                }));

                zoomVideo.on('connection-change', (payload) => {
                    if (payload.state === 'Fail') {
                        if (onDisconnected) {
                            onDisconnected();
                        }
                    }
                });

                pollDevices();

                await new Promise<void>((resolve) => {
                    setTimeout(resolve, 200);
                });

                await createLocalVideoTrack();

                // render all existing video streams
                const { userId: localUserId } = zoomVideo.getCurrentUserInfo();
                for (const existingUser of zoomVideo.getAllUser()) {
                    if (
                        existingUser.bVideoOn &&
                        existingUser.userId !== localUserId
                    ) {
                        addRemoteVideo(existingUser.userId);
                    }
                }
            } catch (error) {
                console.warn('error joining session', error);
            }
        },
        [
            zoomVideo,
            pollDevices,
            microphoneOn,
            createLocalVideoTrack,
            addRemoteVideo,
            checkPermissions,
            viewerName,
        ],
    );

    React.useEffect(() => {
        setValue((prev) => ({
            ...prev,
            otherParticipantJoined: remoteParticipants.length > 0,
        }));
    }, [remoteParticipants.length]);

    const endVideoCall = React.useCallback(
        async (andEndSession = false) => {
            if (zoomSession.current) {
                await zoomSession.current.stopVideo();
                await zoomSession.current.stopAudio();
            }

            if (appType === TelehealthAppType.provider && andEndSession) {
                await zoomVideo.leave(andEndSession);
            } else {
                await zoomVideo.leave();
            }

            ZoomVideo.destroyClient();

            connectedToCall.current = false;
        },
        [appType, zoomVideo],
    );

    const endVideoCallRef = React.useRef<(andEndSession?: boolean) => void>(
        () => {},
    );

    React.useEffect(() => {
        // handle participant joining/leaving
        const peerVideoStateChangeListener = (payload: {
            action: 'Start' | 'Stop';
            userId: number;
        }) => {
            const userIdString = payload.userId.toString(10);
            switch (payload.action) {
                case 'Start':
                    addRemoteVideo(payload.userId);
                    break;
                case 'Stop':
                    zoomSession.current
                        ?.detachVideo(payload.userId)
                        .then((removedVideoPlayers) => {
                            const videoPlayers = Array.isArray(
                                removedVideoPlayers,
                            )
                                ? removedVideoPlayers
                                : [removedVideoPlayers];

                            videoPlayers.forEach((videoPlayer) => {
                                videoPlayer?.remove();
                            });

                            remoteVideoPlayers.current = Object.entries(
                                remoteVideoPlayers.current ?? {},
                            ).reduce<Record<string, VideoPlayer | null>>(
                                (acc, [key, value]) => {
                                    if (key !== userIdString) {
                                        acc[userIdString] = value;
                                    }

                                    return acc;
                                },
                                {},
                            );
                        });
                    break;
                default:
                    console.warn(
                        'unknown action type of peer-video-state-change event: "%s"',
                        payload.action,
                    );
                    break;
            }
        };

        const userAddedListener = (
            payload: readonly ParticipantPropertiesPayload[],
        ) => {
            const { userId: localUserId } = zoomVideo.getCurrentUserInfo();

            const addedUsersIds = payload
                .filter(({ userId }) => userId !== localUserId)
                .map(({ userId }) => userId);

            if (addedUsersIds.length > 0) {
                setRemoteParticipants((prev) => [...prev, ...addedUsersIds]);
            }
        };

        const userRemovedListener = (
            payload: readonly ParticipantPropertiesPayload[],
        ) => {
            const { userId: localUserId } = zoomVideo.getCurrentUserInfo();

            const removedUserIds = payload
                .filter(({ userId }) => userId !== localUserId)
                .map(({ userId }) => userId);

            if (removedUserIds.length > 0) {
                setRemoteParticipants((prev) =>
                    prev.filter((userId) => !removedUserIds.includes(userId)),
                );
            }
        };

        const connectionChangeListener = (payload: ConnectionChangePayload) => {
            console.info(
                'connectionChangeListener'.toUpperCase(),
                payload.state,
                payload.reason,
            );

            if (payload.state === ConnectionState.Closed) {
                endVideoCallRef.current();
            }
        };

        zoomVideo.on('peer-video-state-change', peerVideoStateChangeListener);
        zoomVideo.on('user-added', userAddedListener);
        zoomVideo.on('user-removed', userRemovedListener);
        zoomVideo.on('connection-change', connectionChangeListener);

        return () => {
            zoomVideo.off(
                'peer-video-state-change',
                peerVideoStateChangeListener,
            );
            zoomVideo.off('user-added', userAddedListener);
            zoomVideo.off('user-removed', userRemovedListener);
            zoomVideo.off('connection-change', connectionChangeListener);
        };
    }, [zoomVideo, addRemoteVideo]);

    React.useEffect(() => {
        endVideoCallRef.current = endVideoCall;
    }, [endVideoCall]);

    React.useEffect(() => {
        contextAlive.current = true;

        return () => {
            contextAlive.current = false;
            endVideoCallRef.current();
            clearInterval(pollIdRef.current);
        };
    }, []);

    return {
        microphoneOn,
        cameraOn,
        otherParticipantJoined,
        hasLocalVideo: localVideoTrack !== null,
        hasRemoteVideo:
            Object.keys(remoteVideoPlayers.current ?? {}).length > 0,
        localVideoTrack,
        localVideoRef,
        hasReceivedPermissions,
        hasLostPermissions,
        toggleMicrophone,
        toggleCamera,
        joinSession,
        endVideoCall,
        userPermissionsGranted,
        setSessionMetaData,
        switchCamera,
        canSwitchCameras: availableVideoDeviceIds.length > 1,
    };
}
